<template>
    <section class="pt-3">
        <div class="row mx-0 pt-3">
            <router-link :to="{name: 'manager.permisos.modulos'}" class="col-auto mx-2" :class="$route.name == 'manager.permisos.modulos' ? 'active-tab f-600' : 'f-18 text-grey f-600'">
                Módulos
            </router-link>
            <router-link :to="{name: 'manager.permisos.permisos'}" class="col-auto mx-2" :class="$route.name == 'manager.permisos.permisos' ? 'active-tab f-600' : 'f-18 text-grey f-600'">
                Permisos
            </router-link>
        </div>
        <div class="custom-scroll overflow-auto pb-3" style="height:calc(100vh - 124px)">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {

        }
    }
}
</script>
<style lang="scss" scoped>

a{
    text-decoration: none !important;
    &:hover{
        color: initial !important;
    }
}
 $color: #000000;

.active-tab{
    color: $color !important;
    font-size: 18px;
    border-bottom: 5px solid $color;
    &:hover{
        color: $color !important;
    }
}
</style>