<template>
    <section class="px-4">
        <div class="row mx-0 align-items-center mt-5 mb-4">
            <div class="col-4 px-0 f-18 text-black f-600">
                Permisos por módulo
            </div>
            <button type="button" class="btn-general-small" @click="crearPermiso()">
                Crear
            </button>
            <div class="col-xl-3 col-lg-4">
                <el-input v-model="buscar_permisos" class="w-100 br-20" size="medium" placeholder="Buscar permiso" clearable />
            </div>
        </div>
        <div v-for="(modulo, f) in filtroModulos" :key="f" class="mb-5">
            <div class="row mx-0 my-3">
                <div class="col-auto px-4" />
                <div class="col-auto px-0 text-black f-600 f-17">
                    {{ modulo.nombre }}
                </div>
            </div>
            <draggable :list="modulos[f].permisos" handle=".handle" tag="div" @change="cambioPosicionPermisos">
                <div v-for="(data, p) in modulo.permisos" :key="data.id" class="row mx-0 my-2">
                    <i class="icon-drag f-25 cr-pointer text-grey mt-1" :class="buscar_permisos ? 'cr-bloqueo' : 'handle'" />
                    <div class="col-3">
                        <el-input v-model="data.nombre" disabled size="medium" class="w-100" />
                    </div>
                    <div class="col-3 pl-0">
                        <el-input v-model="data.guardian" disabled size="medium" class="w-100" />
                    </div>
                    <div class="d-middle-center px-0" style="width:35px;">
                        <el-tooltip placement="bottom" content="Permiso de acceso" effect="light">
                            <i :class="data.tipo == 1 ? 'icon-eye f-12 text-black' : 'icon-cursor-default-outline text-black'" />
                        </el-tooltip>
                    </div>
                    <div class="d-middle-center px-2" style="width:35px;">
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:250px">
                                {{ data.descripcion }}
                            </div>
                            <i class="icon-info-circled-alt text-black f-18" />
                        </el-tooltip>
                    </div>
                    <div class="col-auto d-middle-center px-0">
                        <el-tooltip placement="bottom" content="Editar" effect="light" class="ml-3">
                            <button type="button" class="btn-action" @click="editarPermiso(data, f, p)">
                                <i class="icon-pencil-outline" />
                            </button>
                        </el-tooltip>
                    </div>
                    <div class="col-auto d-middle-center px-0">
                        <el-tooltip placement="bottom" content="Eliminar" effect="light">
                            <button type="button" class="btn-action ml-2" @click="eliminar(data.id, f, p)">
                                <i class="icon-trash-empty" />
                            </button>
                        </el-tooltip>
                    </div>
                </div>
            </draggable>
        </div>
        <!-- Partials -->
        <modal-eliminar ref="modalEliminarPermiso" titulo="Eliminar permiso" mensaje="¿Desea eliminar este permiso?" @eliminar="eliminarPermiso" />
        <modal-crear-permiso ref="modalCrearPermiso" @crear="listaCrear" @editar="listaEditar" />
    </section>
</template>

<script>
import Permisos from "~/services/permisos"
export default {
    components: {
        modalCrearPermiso: () => import ('./partials/modalCrearAdministrador')
    },
    data(){
        return {
            idEliminar: '',
            buscar_permisos: '',
            modulos: [],
            model: {
                tipo: 1,
                nombre: null,
                guardian: null,
            },
            indiceModulo: null,
            indicePermiso: null,
        }
    },
    computed: {
        filtroModulos(){
            let datosCopy = _.cloneDeep(this.modulos)

            return datosCopy.filter(el =>{
                let permiso = el.permisos.filter(el2 => 
                    !this.buscar_permisos
                    || el2.nombre.toLowerCase().includes(this.buscar_permisos.toLowerCase())
                    || el2.guardian.toLowerCase().includes(this.buscar_permisos.toLowerCase())
                )
                el.permisos = permiso
                return permiso.length > 0
            })
        },  
    },
    mounted(){
        this.listarPermisos()  
    },
    methods: {
        async listarPermisos(){
            try {
                
                const { data } = await Permisos.listarPermisos()
                this.modulos = data
                
            } catch (e){
                this.error_catch(e)
            }
        },
        listaCrear(model){
            let modulo = this.modulos.find(el => el.id == model.id_modulo)
            modulo.permisos.push(model)
        },
        listaEditar(model){
            this.modulos[this.indiceModulo].permisos.splice(this.indicePermiso,1, model)
        },
        async cambioPosicionPermisos(event){
            try {
                const datos = {
                    antiguaPosicion: event.moved.oldIndex + 1,
                    nuevaPosicion: event.moved.newIndex + 1,
                    id: event.moved.element.id,
                    id_modulo: event.moved.element.id_modulo,
                }
                const { data } = await Permisos.cambioPosicionPermisos(datos)
                this.notificacion('Mensaje', data.mensaje, 'success')
            } catch (e){
                this.error_catch(e)
            }
        },
        eliminar(id , f, p){
            this.indiceModulo = f
            this.indicePermiso = p
            this.idEliminar = id
            this.$refs.modalEliminarPermiso.toggle()
        },
        async eliminarPermiso(){
            try {
                const {data} = await Permisos.eliminarPermiso(this.idEliminar)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.$refs.modalEliminarPermiso.toggle()
                this.modulos[this.indiceModulo].permisos.splice(this.indicePermiso,1)
            } catch (e){
                this.error_catch(e)
            }
        },
        crearPermiso(){
            this.$router.push({name: 'manager.permisos.crearPermiso'});
            /* this.$refs.modalCrearPermiso.toggle() */
        },
        editarPermiso(data, f, p){
            this.indiceModulo = f
            this.indicePermiso = p
            this.$router.push({name: 'manager.permisos.crearPermiso', params: {datos: data}});
            /* this.$refs.modalCrearPermiso.toggle(data) */
        }
    }
}
</script>

<style lang="scss" scoped>
section{
    border-radius: 12px 12px 0px 0px;
}
.br-12{
    border-radius: 12px !important;
}
.show-button{
    background-color: #FF5E2B;
    color: #ffffff;
    border-radius: 8px;
}
.button-cursor{
    opacity: 0.5;
}
.text-black{
    color: #000000;
}
.bg-light-f5{
    background-color: #F5F5F5;
}
.button-accept{
    background-color: #F5F5F5;
    color: #707070;
    &:hover{
        color: #ffffff !important;
        background-color: #707070 !important;
    }
}
.hover-icons{
    color: #707070;
    &:hover{
        color: #000000;
    }
}
.h{
    background-color: #F5F5F5;
    border: #DBDBDB;
    color: #000000;
    border-radius: 12px;    
}
.cr-bloqueo{
    cursor: not-allowed;
}
</style>