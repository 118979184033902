<template>
    <section>
        <div class="row mx-0 pt-2">
            <div class="col-4 px-0">
                <div class="d-flex mx-0 justify-center">
                    <div class="col pl-0 input-edit">
                        <el-input v-model="buscar" class="w-100" placeholder="Buscar Proyecto" />
                    </div>
                    <div class="d-middle-center bg-grr-general br-12 text-white mx-1 cr-pointer" style="width:44px;height:44px;" @click="AbrirCrearProyecto(null)">
                        <i class="icon-plus f-20" />
                    </div>
                </div>
                <div class="row mx-0 border br-10 mt-2">
                    <content-loader v-show="loading" />
                    <div class="custom-scroll overflow-auto w-100 bg-white" style="height:calc(100vh - 124px)">
                        <div v-for="(t, idx) in proyectosFiltrados" :key="idx" class="row mx-0 d-middle border-bottom cr-pointer" style="height:91px;" @click="activarCar(t)">
                            <div :class="`mx-2 px-1 h-80 br-5 ${id_proyecto===t.id?'bg-gr-red':''}`" />
                            <div class="col px-0 tres-puntos f-15">
                                <div class="row mx-0 f-600 pl-1">
                                    <p class="tres-puntos">{{ t.nombre }}</p>
                                </div>
                                <div class="row mx-0 align-items-center">
                                    <div class="col pl-1 tres-puntos">
                                        <strong> Proyecto:</strong> {{ t.tipo===1 ? 'Masivo': 'Vip' }}
                                    </div>
                                    <div class="d-middle-center bg-gr-general px-2 br-12 text-white" style="min-width:90px;height:28px;">
                                        <i class="icon-storefront-outline" /> 
                                        <span>{{ t.tiendas_activas }} / {{ t.cant_tiendas }}</span>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-8 pl-1 tres-puntos">
                                        <strong> Estado: </strong>  {{ t.estado===1 ? 'Activo': 'Inactivo' }}
                                        <i :class="t.estado == 1 ? 'icon-ok-circled text-gr-general f-18' : 'icon-attention-1 text-gr-red f-18'" />
                                    </div>
                                </div>
                            </div>
                            <i :class="`icon-angle-right f-18 ${id_proyecto===t.id?'text-gr-red':''}`" />
                        </div>               
                        <div v-if="proyectosFiltrados.length <= 0" class="row mx-0 justify-center">
                            <div class="d-middle-center br-12 position-relative cr-pointer" style="width:162px;height:162px;background-color:#F8F9FF;" @click="AbrirCrearProyecto(null)">
                                <i class="icon-proyectos f-60" />
                                <p class="text-muted2 f-600 f-15 position-absolute text-center" style="top:165px;">
                                    Aún no se han creado proyectos
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8 pr-0">
                <div class="bg-white w-100 border br-10" style="height:calc(100vh - 74px)">
                    <div v-if="id_proyecto !== null" class="row mx-0 py-3 border-bottom">
                        <div class="col-5 d-middle">
                            <p class="text-muted2 f-15 f-600">
                                {{ detalle.nombre }}
                            </p>
                        </div>
                        <div class="col-auto px-2 ml-auto">
                            <div class="d-middle-center text-white br-12 cr-pointer" :class="detalle.estado===1?'bg-grr-red': 'bg-grr-general'" style="width:86px;height:35px;" @click="AbrirActivarProyecto()">
                                {{ detalle.estado===1 ? 'Inactivar': 'Activar' }}
                            </div>
                        </div>
                        <div class="col-auto px-2">
                            <el-tooltip slot="reference" class="item" effect="light" content="Editar proyecto" placement="bottom">
                                <div class="bg-gr-general text-white d-middle-center br-10 cr-pointer" style="width:32px;height:32px;" @click="AbrirCrearProyecto(detalle)">
                                    <i class="icon-pencil-outline f-20" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto px-2">
                            <el-tooltip slot="reference" class="item" effect="light" content="Eliminar proyecto" placement="bottom">
                                <div class="bg-gr-red text-white d-middle-center br-12 cr-pointer" style="width:32px;height:32px;" @click="AbrirEliminarProyecto()">
                                    <i class="icon-trash f-20" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto px-2">
                            <el-tooltip slot="reference" class="item" effect="light" content="Comentarios" placement="bottom">
                                <div class="btn-edit d-middle-center br-12 cr-pointer" style="width:32px;height:32px;" @click="comentarios">
                                    <i class="icon-message-text f-20" />
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                    <lugaresOperacion v-show="id_proyecto !== null" ref="lugaresOperacion" />
                </div>
            </div>
        </div>
        <!-- Modales -->
        <modalCrearProyecto ref="CrearProyecto" @crear="recargarCrear" @actualizar="recargar" />
        <modalActivarProyectos ref="ActivarProyectos" @estado="cambiarEstado" />
        <modalEliminarProyectos ref="EliminarProyectos" @actualizar="recargar" />
        <modalComentarios ref="modalComentarios" tipo="comentarios" titulo="Comentarios del proyecto" />
        <!-- Modales -->
    </section>
</template>
<script>
import Proyectos from '~/services/proyectos/proyectos'
import {mapGetters} from 'vuex'

export default {
    components: {
        modalCrearProyecto: () => import('./partials/modalCrearProyecto'),
        modalActivarProyectos: () => import('./partials/modalActivarProyectos'),
        modalEliminarProyectos: () => import('./partials/modalEliminarProyectos'),
        lugaresOperacion: () => import('./lugaresOperacion')
    },
    data(){
        return{
            loading: false,
            buscar: null,
            proyectos:[],
  
        }
    },
    computed:{
        proyectosFiltrados(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.proyectos.filter(el => el.nombre.toLowerCase().includes(this.buscar.toLowerCase()))

            }else{
                return this.proyectos
            }
        },
        ...mapGetters({
            id_proyecto: 'proyectos/proyectos/id_proyecto',
            detalle: 'proyectos/proyectos/detalle'
        })
    },
    mounted(){
        this.recargar()
    },
    methods: {
        recargar(){
            this.$store.commit('proyectos/proyectos/set_id_proyecto', null)

            if(this.$refs.lugaresOperacion){
                this.$refs.lugaresOperacion.limpiarVista()
            }
            this.listarProyectos()
        },
        async listarProyectos(){
            try {
                this.loading = true
                const {data} = await Proyectos.listarProyectos()
                this.loading = false
                this.proyectos = data.proyectos
                if(this.id_proyecto !== null){
                    const found = data.proyectos.find(e => e.id  === this.id_proyecto)
                    this.$store.commit('proyectos/proyectos/set_detalle', found)
                }
                
            } catch (e){
                this.error_catch(e)
            }
        },
        async recargarCrear(){
            await this.listarProyectos()
            let detalleInical ={
                id: null,
                nombre: null,
                tipo: 1,
                estado: 1,
                descripcion: null,
                base_datos: null,
                dominio: null,
                bucket_s3: null,
                cant_tiendas: null,
                tiendas_activas: null,
                prefijos: []
            }
            this.$store.commit('proyectos/proyectos/set_id_proyecto', null)
            this.$store.commit('proyectos/proyectos/set_detalle', detalleInical)
        },
        activarCar(t){
            try {
                this.$refs.lugaresOperacion.limpiarVista()
                this.$store.commit('proyectos/proyectos/set_id_proyecto', t.id)
                this.$store.commit('proyectos/proyectos/set_detalle', t)
                this.$store.dispatch('proyectos/proyectos/action_detalle_proyecto', {id_proyecto:t.id})
                
            } catch (e){
                this.error_catch(e)
            }
        },
        AbrirCrearProyecto(info){
            console.log(info, this.id_proyecto);
            this.$router.push({name: 'manager.crear-proyecto', params: {id_proyecto: this.id_proyecto, data: info}  });
            /* this.$refs.CrearProyecto.toggle(info); */
        },
        AbrirActivarProyecto(){
            this.$refs.ActivarProyectos.toggle(this.detalle);
        },
        AbrirEliminarProyecto(){
            this.$refs.EliminarProyectos.toggle(this.detalle.id);
        },
        comentarios(){
            this.$refs.modalComentarios.toggle()
        },
        cambiarEstado(){
            this.listarProyectos()
            // let index = this.proyectos.findIndex(e => e.id  === this.id_proyecto)
            // if(index >=0){
            //     this.proyectos[index].estado = this.detalle.estado
            // }

        }
    }
}
</script>
<style lang="scss">
.input-edit{
    .el-input{
        .el-input__inner{
            border-radius: 20px;
        }
    }
}
.bg-white{
    background: #FFFFFF;
}
.select-bandera{
    .el-select{
        &.w-100{
            .el-input{
                &.el-input--suffix{
                    .el-input__inner{

                        text-align: center;
                    }
                }
            }
        }
    }
}
.select-pais{
    .el-select{
        &.w-100{
            .el-input{
                &.el-input--suffix{
                    &.is-focus{
                        .el-input__inner{
                            text-align: center;
                            border: 1px solid #FF612B !important;
                        }
                    }
                    .el-input__inner{
                            text-align: center;
                            border: 1px solid #FF612B !important;
                        }

                }
            }
        }
    }
}
</style>
