<template>
    <section class="pt-3">
        <!-- @adicional="crearEditar" -->
        <div class="row mx-0 align-items-center mb-5">
            <i class="icon-back cr-pointer f-20 text-black" @click="$router.back();" />
            <div class="col-auto px-2 text-black f-18 f-600">
                {{ editar ? 'Editar' : 'Crear' }} permiso
            </div>
        </div>
        <ValidationObserver ref="validacion" tag="div" class="pl-4">
            <div class="row mx-0">
                <div class="col-3 pl-2">
                    <ValidationProvider v-slot="{errors}" vid="nombre" rules="required|max:30" name="nombre">
                        <p class="text-black f-12 pl-3">
                            Nombre del permiso
                        </p>
                        <el-input v-model="model.nombre" size="medium" maxlength="30" placeholder="Nombre" class="w-100">
                            <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                        </el-input>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-auto d-middle pt-3">
                    <div class="cr-pointer pr-1" :class="`square-access${model.tipo == 1 ? '-active' : ''}` " @click="cambiarTipo(1)">
                        <i class="icon-eye f-12 mr-1" />
                    </div>
                    <span class="mx-1 text-black">
                        Acceso
                    </span>
                </div>
                <div class="col-auto d-middle pt-3">
                    <div class="cr-pointer" :class="`square-access${model.tipo == 2 ? '-active' : ''}`" @click="cambiarTipo(2)">
                        <i class="icon-cursor-default-outline f-14" />
                    </div>
                    <span class="mx-1 text-black">
                        Acción
                    </span>
                </div>
            </div>
            <div class="row mx-0 my-3">
                <div class="col-5 pl-2">
                    <p class="text-black f-12 pl-3">
                        Módulo
                    </p>
                    <el-select v-model="model.id_modulo" :disabled="editar" size="medium" placeholder="Seleccionar módulo" class="w-100">
                        <el-option
                        v-for="item in modulos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-5">
                    <ValidationProvider v-slot="{errors}" vid="guardian" rules="required|max:50" name="guardian">
                        <p class="text-black pl-3 f-12">
                            Guardián
                        </p>
                        <el-input v-model="model.guardian" size="medium" maxlength="50" placeholder="Guardían" class="w-100">
                            <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                        </el-input>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-10 pl-2">
                    <ValidationProvider v-slot="{errors}" vid="descripcion" rules="required|max:300" name="descripción">
                        <p class="text-black pl-3 f-12">
                            Descripción
                        </p>
                        <el-input v-model="model.descripcion" maxlength="300" placeholder="Descripción" class="w-100" type="textarea" :rows="3" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 mt-4">
                <div class="col-auto px-2">
                    <button type="button" class="btn-general-small" @click="crearEditar">
                        {{ editar ? 'Editar' : 'Crear' }} permiso
                    </button>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>

<script>
import Permisos from "~/services/permisos"
export default {
    data(){
        return {
            modulos: [],
            model: {
                tipo: 1,
                nombre: null,
                guardian: null,
                id_modulo: null,
                descripcion: null,
            },
            editar: false
        }
    },
    mounted(){
        this.cargarDatos(this.$route.params.datos);
    },
    methods: {
        cargarDatos(data){
            this.listarModulos()
            if (data){
                this.model = _.cloneDeep(data)
                this.editar = true
            }else{
                this.editar = false
            }
            /* this.$refs.modalCrearAdministrador.toggle(); */
        },
        async listarModulos(){
            try {
                
                const { data } = await Permisos.listarModulos()
                this.modulos = data
                
            } catch (e){
                this.error_catch(e)
            }
        },
        crearEditar(){
            if (this.editar){
                this.editarPermiso()
            } else {
                this.crearPermiso()
            }
        },
        async crearPermiso(){
            try {
                let valid = await this.$refs.validacion.validate()

                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return
                }
                const { data } = await Permisos.crearPermiso(this.model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.limpiar()
                this.$emit('crear', data.model)
                
                this.$router.back();
                
            } catch (e){
                this.error_catch(e, 'validacion')
            }
        },
        async editarPermiso(){
            try {

                let valid = await this.$refs.validacion.validate()

                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return
                }

                const { data } = await Permisos.editarPermiso(this.model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.limpiar()
                this.$emit('editar', data.model)
                this.$router.back();
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model = {
                tipo: 1,
                nombre: null,
                guardian: null,
                id_modulo: null,
                descripcion: null,
            }
            this.$refs.validacion.reset();
        },
        cambiarTipo(val){
            this.model.tipo = val
            this.$refs.validacion.reset();
        },
    }
}
</script>

<style lang="scss" scoped>
section{
    border-radius: 12px 12px 0px 0px;
}
.br-12{
    border-radius: 12px !important;
}

.square-access{
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 8px;
    &-active{
        color: #FFFFFF;
        @extend .square-access;
        background-color: #29D884 !important;
        border-color: #29D884 !important;
        border-radius: 8px;
    }
}


.icon-eye::before{
    margin: 0px !important;
}

.show-button{
    background-color: #FF5E2B;
    color: #ffffff;
    border-radius: 8px;
}
.button-cursor{
    opacity: 0.5;
}
.text-black{
    color: #000000;
}
.bg-light-f5{
    background-color: #F5F5F5;
}
.button-accept{
    background-color: #F5F5F5;
    color: #707070;
    &:hover{
        color: #ffffff !important;
        background-color: #707070 !important;
    }
}
.hover-icons{
    color: #707070;
    &:hover{
        color: #000000;
    }
}
.h{
    background-color: #F5F5F5;
    border: #DBDBDB;
    color: #000000;
    border-radius: 12px;    
}
.cr-bloqueo{
    cursor: not-allowed;
}
</style>