<template>
    <section>
        <div class="row mx-0">
            <div class="menu-prinicipal">
                <div class="row mx-0 mb-2">
                    <div class="col pl-1 my-auto">
                        <el-input v-model="buscar" placeholder="Buscar Avatar" class="br-20" />
                    </div>
                    <el-popover
                    placement="bottom-end"
                    popper-class="p-0"
                    v-model="closeCrear"
                    width="300"
                    trigger="click"
                    >
                        <div class="row mx-0 justify-center align-items-center position-relative" style="background-image: url('https://i.pinimg.com/736x/1c/27/d7/1c27d7cff2c7263ea6d14d875bcc9394.jpg');height:50px;">
                            <div class="ml-auto mr-2 bg-gr-general text-white rounded-circle d-middle-center cr-pointer" style="width:20px;height:20px;" @click=" closeCrear = false">
                                <i class="icon-cancel" />
                            </div>
                        </div>
                        <div class="row mx-0 mt-3 justify-center text-muted2 f-600 f-17">
                            Crear grupo de avatares
                        </div>
                        <ValidationObserver ref="validacion" v-slot="{ valid }">
                            <div class="row mx-0 my-4">
                                <div class="col px-5">
                                    <label class="px-3">
                                        Nombre del grupo
                                    </label>
                                    <ValidationProvider v-slot="{ errors }" rules="required|max:30" name="nombre grupo">
                                        <el-input v-model="nombre_grupo" maxlength="30" show-word-limit/>
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row mx-0 justify-center pb-3">
                                <div class="col-5 px-1">
                                    <div class="bg-whitesmoke border cr-pointer p-2 d-middle-center br-6 f-17" @click=" closeCrear = false">
                                        Cancelar
                                    </div>
                                </div>
                                <div class="col-5 px-1">
                                    <div class="bg-grr-red text-white cr-pointer p-2 d-middle-center br-6 f-17" @click="crear_grupo_avatar(valid)">
                                        Aceptar
                                    </div>
                                </div>
                            </div>
                        </ValidationObserver>
                        <el-tooltip slot="reference" class="item" effect="light" content="Crear grupo de avatares" placement="bottom">
                            <div class="bg-grr-general cr-pointer d-middle-center br-10" style="width:44px;height:44px;">
                                <i class="icon-plus f-20 text-white" />
                            </div>
                        </el-tooltip>
                    </el-popover>
                </div>
                <div class="border br-10 overflow-auto custom-scroll" style="width:400px;height:calc(100vh - 115px);">
                    <div
                    v-for="(grupo, idx) in grupos.filter(c => !buscar
                        || c.nombre.toLowerCase().includes(buscar.toLowerCase()))"
                    :key="idx"
                    class="row mx-0 d-middle border-bottom cr-pointer"
                    style="height:50px;"
                    @click="activo=grupo.id"
                    >
                        <div :class="`mx-2 px-1 h-80 br-5 ${activo==grupo.id?'bg-gr-red':''}`" />
                        <div class="col-9 tres-puntos f-15">
                            <div class="row mx-0 f-600">
                                <p class="tres-puntos">{{grupo.nombre}}</p>
                            </div>
                        </div>
                        <el-tooltip class="item" effect="light" placement="bottom">
                            <div slot="content" class="" style="max-width:150px;">
                                Cantidad de avatares
                            </div>
                            <div class="br-20 mx-2 bg-gr-red d-middle-center text-white" style="min-width:30px;">
                                <span>{{grupo.avatar_count}}</span>
                            </div>
                        </el-tooltip>
                        <i :class="`icon-angle-right f-18 ${activo==grupo.id?'text-gr-red':''}`" />
                    </div>
                    <content-loader v-show="loading" />
                    <div v-if="grupos.length == 0" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-17 f-600" style="width:225px;">
                            <p>Aun no se han creado avatares</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 border br-t-10 menu-prinicipal ml-3" style="width: calc(100vw - 560px);">
                <detalle v-if="activo" :id-padre="activo" @actualizar="get_grupos_avatares" />
            </div>
        </div>
    </section>
</template>

<script>
import Avatar from "~/services/avatares/index.js"

export default {
    metaInfo: () =>({ title: 'Avatares'}),
    components: {
        detalle: () => import('./detalle'),
    },
    data(){
        return {
            closeCrear: false,
            loading: false,
            nombre_grupo: '',
            buscar: '',
            activo: null,
            grupos: []
        }
    },    
    mounted () {
        this.get_grupos_avatares()
    },
    methods: {
        async get_grupos_avatares(){
            try {
                this.loading = true
                const { data } =  await Avatar.get_grupos_avatares()
                this.grupos = data.avatares
            } catch (error) {
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async crear_grupo_avatar(valid){
            try {
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                let model = {
                    id: null,
                    nombre: this.nombre_grupo
                }
                const { data } =  await Avatar.crear_grupo_avatar(model)
                this.closeCrear = false
                this.nombre_grupo = ''
                this.$refs.validacion.reset()
                this.get_grupos_avatares()

            } catch (error) {
                this.error_catch(error)
            }
        }
    }
}
</script>

<style lang="css" scoped>
.menu-prinicipal{

    background: #fff;
}
</style>
