<template>
    <section>
        <div class="row mx-0 mt-5">
            <div class="col-auto f-18 text-black f-600">
                Módulos para permisos
            </div>
        </div>
        <ValidationObserver ref="validacion" v-slot="{ valid }" tag="div" class="row mx-0 mt-3 mb-5">
            <ValidationProvider v-slot="{errors}" tag="div" class="col-3" vid="nombre" rules="required|max:30" name="nombre">
                <el-input v-model="model.nombre" maxlength="30" size="medium" placeholder="Nombre del módulo" class="w-100">
                    <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                </el-input>
                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
            </ValidationProvider>
            <div class="col-auto">
                <button type="button" class="btn-general-small px-4" @click="crearModulo(valid)">
                    Crear
                </button>
            </div>
        </ValidationObserver>
        <ValidationObserver ref="validacion2" v-slot="{ valid }">
            <div v-for="(data, p) in modulos" :key="p" class="row mx-0 my-2">
                <div class="col-3">
                    <ValidationProvider v-slot="{errors}" :vid="'nombre'+p" rules="required|max:30" name="nombre">
                        <el-input v-model="data.nombre" :disabled="!data.edit" size="medium" class="w-100" maxlength="30">
                            <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                        </el-input>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>            
                <div class="col-auto d-middle-center">
                    <el-tooltip v-if="!data.edit" placement="bottom" content="Editar" effect="light">
                        <button type="button" class="btn-action" @click="data.edit = !data.edit">
                            <i class="icon-pencil-outline hover-icons cr-pointer" />
                        </button>
                    </el-tooltip>
                    <button v-if="data.edit" type="button" class="btn-action-success" @click="editarModulo(valid,data)">
                        <i class="icon-ok-circled-outline" />
                    </button>
                </div>
                <div class="col-auto d-middle-center px-0">
                    <el-tooltip placement="bottom" content="Eliminar" effect="light">
                        <button type="button" class="btn-action" @click="eliminar(data.id)">
                            <i class="icon-trash-empty" />
                        </button>
                    </el-tooltip>
                </div>
            </div>
        </ValidationObserver>
        <modal-eliminar ref="modalEliminarModulo" titulo="Eliminar módulo" mensaje="¿Desea eliminar este módulo?" @eliminar="eliminarModulo" />
    </section>
</template>

<script>
import Permisos from "~/services/permisos"
export default {
    data(){
        return {
            idEliminar: '',
            buscar_permisos: '',
            modulos: [],
            model: {
                nombre: null,
            },
        }
    },
    mounted(){
        this.listarModulos()  
    },
    methods: {
        async listarModulos(){
            try {
                
                const { data } = await Permisos.listarModulos()
                this.modulos = data.map(el => ({...el, edit: !!el.edit}))
                
            } catch (e){
                this.error_catch(e)
            }
        },
        async crearModulo(valid){
            try {
                
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return
                }
                const { data } = await Permisos.crearModulo(this.model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                data.model.edit = false
                this.modulos.push(data.model)
                this.limpiar()
                this.$refs.validacion.reset();

            } catch (e){
                this.error_catch(e, 'validacion')
            }
        },
        async editarModulo(valid, dato){
            try {
                if(valid){
                    const { data } = await Permisos.editarModulo(dato)
                    this.notificacion('Mensaje', data.mensaje, 'success')
                    dato.edit = !dato.edit
                    dato = data.model
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        eliminar(id){
            this.idEliminar = id
            this.$refs.modalEliminarModulo.toggle()
        },
        async eliminarModulo(){
            try {
                const {data} = await Permisos.eliminarModulo(this.idEliminar)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.$refs.modalEliminarModulo.toggle()
                let idx = this.modulos.findIndex(el => el.id == this.idEliminar)
                this.modulos.splice(idx,1)
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model = {
                tipo: 1,
                nombre: null,
                guardian: null,
            }
        },
    }
}
</script>