<template>
    <section class="box pt-0" style="height:calc(100vh - 62px)">
        <div class="row align-items-center mx-0 border-bottom py-2 px-3">
            <div class="col-auto px-0">
                <div class="bg-black d-middle text-white br-50 cr-pointer" style="height:25px;width:25px;">
                    <i class="icon-left f-18" @click="$router.go(-1)" />
                </div>
            </div>
            <div class="col-auto d-middle text-black">
                Asignación de permisos
            </div>
            <div class="col-auto d-middle pr-0 f-600 text-black">
                Proyecto:
            </div>
            <div class="col-3">
                <el-select v-model="idProyecto" placeholder="Seleccione..." class="select-transparent">
                    <el-option
                    v-for="item in proyectos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-auto d-middle pr-0 f-600 text-black">
                Módulos:
            </div>
            <div class="col-3">
                <el-select v-model="idModulo" placeholder="Seleccione..." class="select-transparent">
                    <el-option
                    v-for="item in modulos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
        <div id="cont-scroll" class="custom-scroll overflow-auto">
            <table class="table">
                <tr class="border-right">
                    <th class="border-bottom pb-2 fixeds-search bg-white" style="width:320px;">
                        <div class="d-flex align-items-end" style="height:80px;">
                            <el-input v-model="buscarPermisos" class="w-100 br-20" placeholder="Buscar Permiso" clearable />
                        </div>
                    </th>
                    <th v-for="(permiso, p) in filtroPermisos" :key="p" class="text-center fixeds-top bg-white border-left border-right border-bottom" style="width:125px;">
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:200px">
                                {{ permiso.descripcion }}
                            </div>
                            <div class="d-flex flex-column justify-content-end" style="height:80px;">
                                <i class="text-black f-20" :class="permiso.tipo == 1 ? 'icon-eye' : 'icon-cursor-default-outline'" />
                                <p class="text-muted2 f-14 px-2">
                                    {{ permiso.nombre }}
                                </p>
                            </div>
                        </el-tooltip>
                    </th>
                </tr>
                <tr>
                    <td class="fixeds bg-light-f5">
                        <div class="d-middle justify-center text-muted2 px-4 " style="height:60px;">
                            Administradores
                        </div>
                    </td>
                    <td v-for="(permiso, p) in filtroPermisos" :key="p" class="text-center bg-light-f5 border-left border-right border-bottom" style="width:125px;">
                        <div class="d-middle-center" style="height:60px;">
                            <el-checkbox v-model="permiso.checked" class="checkbox-md" :indeterminate="permiso.total == 2" @change="asignarPermisoMasivo(permiso, 1)" />
                        </div>
                    </td>
                </tr>
                <tr v-for="(user, u) in users" :key="u">
                    <td style="width:250px;" class="border-bottom fixeds bg-white">
                        <div class="d-middle" style="height:52px;">
                            <el-checkbox v-model="user.checked" :disabled="buscarPermisos != ''" class="mx-2 checkbox-md" :indeterminate="user.total == 2" @change="asignarPermisoMasivo(user, 2)" />
                            <img :src="user.imagen" width="40" height="40" class="obj-cover border rounded-circle" />
                            <div class="col-auto f-600 text-muted2">
                                {{ user.nombre }}
                            </div>
                            <i v-show="!user.estado" class="icon-eye-off" />
                        </div>
                    </td>
                    <td v-for="(permiso, p) in filtroPermisos" :key="p" class="border-left border-bottom border-right" style="width:125px;">
                        <div>
                            <div class="d-middle-center" style="height:60px;">
                                <el-checkbox :key="update" :checked="permisoChekeado(user.id, permiso.id)" class="checkbox-md" @change="asignarPermiso(permiso.id, user.id)" />
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </section>
</template>

<script>
import Permisos from "~/services/permisos"
export default {
    data(){
        return{
            buscarPermisos: '',
            proyectos: [],
            modulos: [],
            permisos: [],
            users: [],
            usersPermisos: [],
            idProyecto: null,
            idModulo: null,
            update: true
        }
    },
    computed: {
        filtroPermisos(){
            return this.permisos.filter(el =>
                !this.buscarPermisos
                || el.nombre.toLowerCase().includes(this.buscarPermisos.toLowerCase())
            )
        },  
    },
    watch: {
        idProyecto(val, oldVal){
            if (oldVal == null) return
            this.listarUserPermisos()  
        },
        idModulo(val, oldVal){
            if (oldVal == null) return
            this.listarUserPermisos()  
        },
        buscarPermisos(){
            this.update = !this.update
        },
    },
    async mounted(){
        await this.listarProyectos()
        await this.listarModulos()
        await this.listarUserPermisos()
    },
    methods:{
        async listarProyectos(){
            try {
                const { data } = await Permisos.listarProyectos()
                this.proyectos = data.proyectos
                this.idProyecto = this.proyectos[0].id
            } catch (e){
                this.error_catch(e)
            }
        },
        async listarModulos(){
            try {
                
                const { data } = await Permisos.listarModulos()
                this.modulos = data
                this.idModulo = this.modulos[0].id
                
            } catch (e){
                this.error_catch(e)
            }
        },
        async listarUserPermisos(){
            try {
                const { data } = await Permisos.listarUserPermisos(this.idProyecto, this.idModulo)
                this.permisos = data.permisos
                this.users = data.users
                this.usersPermisos = data.usersPermisos
                this.update = !this.update
            } catch (e){
                this.error_catch(e)
            }
        },
        async asignarPermisoMasivo(permiso, tipo){
            try {
                let model = {
                    id: permiso.id,
                    checked: permiso.checked,
                    tipo,
                    id_proyecto: this.idProyecto,
                    id_modulo: this.idModulo,
                }
                const { data } = await Permisos.asignarPermisoMasivo(model)
                this.usersPermisos = data.model
                this.update = !this.update
                this.calcularIndeterminate()
            } catch (e){
                this.error_catch(e)
            }
        },
        async asignarPermiso(idm_permiso, id_user){
            try {
                let model = {
                    checked: !this.permisoChekeado(id_user,idm_permiso),
                    idm_permiso,
                    id_user,
                    id_proyecto: this.idProyecto,
                    id_modulo: this.idModulo,
                }
                
                const { data } = await Permisos.asignarPermiso(model)
                this.usersPermisos = data.model
                this.update = !this.update
                this.calcularIndeterminate()
            } catch (e){
                this.error_catch(e)
            }
        },
        permisoChekeado(idUser, idPermiso){
            return !!this.usersPermisos.find(el => el.id_user == idUser && el.idm_permiso == idPermiso)
        },
        calcularIndeterminate(){
            let totalPermisos = this.permisos.length
            let totalUsers = this.users.length
            this.users = this.users.map(u => {
                let totalUsersPermisos = this.usersPermisos.filter(el => el.id_user == u.id).length
                u.total = totalUsersPermisos == 0 ? 0 : (totalUsersPermisos >= totalPermisos ? 1 : 2)
                u.checked = totalUsersPermisos == totalPermisos
                return u
            })
            this.permisos = this.permisos.map(p => {
                let totalUsersPermisos = this.usersPermisos.filter(el => el.idm_permiso == p.id).length
                p.total = totalUsersPermisos == 0 ? 0 : (totalUsersPermisos >= totalUsers ? 1 : 2)
                p.checked = totalUsersPermisos == totalUsers
                return p
            })
        }
    }
}
</script>

<style lang="scss" scoped>
section{ border-radius: 12px 12px 0px 0px; }
.bg-black{background-color: #000000;}
.bg-light-f5{background-color: #F5F5F5;}
.text-black{color: #000000;}
.selected-all{
    height: 40px;
}
.column-width{
    width:110px;
}
.fixeds{
    position: sticky;
    z-index: 5;
    left: 0;
}
.fixeds-top{
    top: 0;
    position: sticky;
    z-index: 5;
}
.fixeds-search{
    position: sticky;
    z-index: 8;
    left: 0;
    top: 0;
}
#cont-scroll{
    max-height: calc(100vh - 123px);
}
.table{
    table-layout: fixed;
    overflow-y: auto;
}
</style>