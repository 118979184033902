<template>
    <section class="bg-white mx-2 mt-2 border" style="height:calc(100vh - 73px)">
        <div class="row mx-0 py-3 pl-3 border-bottom align-items-center">
            <div class="btn-back d-middle-center cr-pointer" @click="$router.back()">
                <i class="icon-left f-12" />
            </div>
            <div class="col-auto text-dark">
                {{ idProyecto ? 'Editar proyecto' : 'Crear Proyecto' }}
            </div>
            <div class="bg-whitesmoke3 text-muted2 px-3 br-12 py-1 ml-auto cr-pointer">
                Cancelar
            </div>
            <div class="bg-grr-red text-white px-3 br-12 py-1 ml-2 mr-2 cr-pointer" @click="modoActualizar ? putProyecto() : postProyecto()">
                Aceptar
            </div>
        </div>
        <ValidationObserver ref="validacion">
            <div class="row mx-0 px-4 pt-3">
                <div class="col-5">
                    <div class="col-12 px-5 my-2">
                        <label class="text-muted2 f-15 px-2">Nombre del Proyecto</label>
                        <ValidationProvider v-slot="{errors}" rules="required|max:40" name="Nombre">
                            <el-input v-model="model.nombre" class="w-100" maxlength="41" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 px-5 my-2">
                        <label class="text-muted2 f-15 px-2">Descripción</label>
                        <div style="width:391px;">
                            <ValidationProvider v-slot="{errors}" rules="required|max:1500" name="Descripción">
                                <el-input v-model="model.descripcion" class="w-100" type="textarea" :rows="3" placeholder="Digite una descripción" maxlength="1501" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="col-12 px-5 d-flex justify-center py-4">
                        <el-radio-group v-model="model.tipo" :disabled="modoActualizar">
                            <el-radio :label="1">
                                Tipo masivo
                            </el-radio>
                            <el-radio :label="2">
                                Tipo VIP
                            </el-radio>
                        </el-radio-group>
                    </div>
                    <div class="col-12 px-5">
                        <div style="width:391px;">
                            <label class="text-muted2 f-15 px-2">Base de Datos</label>
                            <ValidationProvider v-slot="{errors}" rules="required|max:30|espacios" name="Base de Datos">
                                <el-input v-model="model.base_datos" class="w-100" maxlength="31" :disabled="modoActualizar" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="col-12 my-2 px-5 d-flex align-items-center">
                        <div style="width:277px;">
                            <label class="text-muted2 f-15 px-2">Dominio</label>
                            <ValidationProvider v-slot="{errors}" rules="required|max:40|espacios" name="Dominio">
                                <el-input v-model="model.dominio" class="w-100" maxlength="41" :disabled="modoActualizar" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <p class=" mx-3 mt-4 f-14 text-muted2">
                            /YourMarket.com
                        </p>
                    </div>
                </div>
                <div class="col">
                    <p class="f-18 f-600 text-muted2 mb-3">
                        Texto de los roles
                    </p>
                    <div class="row mx-0">
                        <div class="col-3 pl-0">
                            <p class="f-15 pl-3">Empresa</p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:250" name="Empresa">
                                <el-input v-model="model.empresa" class="w-100" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-3">
                            <p class="f-15 pl-3">Vendedor</p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:250" name="Vendedor">
                                <el-input v-model="model.vendedor" class="w-100" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-3">
                            <p class="f-15 pl-3">Cliente</p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:250" name="Cliente">
                                <el-input v-model="model.cliente" class="w-100" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 py-4" />
                    <div class="row mx-0 mt-5">
                        <div class="col-auto pl-0">
                            <p class="f-18 f-600 mb-4">Color del sistema</p>
                            <el-color-picker v-model="colorSistema" />
                        </div>
                        <div class="col-auto">
                            <p class="f-18 f-600 mb-4">Preview del botón general</p>
                            <div class="btn-preview text-center cr-pointer" style="width:193px;">
                                Botón
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>
<script>
import Service from '~/services/proyectos/proyectos'
import { extend } from 'vee-validate'
extend('espacios', {
    validate(value){
        var result = /^\S*$/u.test(value);
        return result;
    },
    params: ['length'],
    message: 'EL campo {_field_} no puede llevar espacios en blanco.'
});

export default {
    data(){
        return {
            modoActualizar: false,
            model: {
                nombre: null,
                descripcion: null,
                tipo: 1,
                estado:1,
                base_datos: null,
                dominio: null,
                empresa: null,
                vendedor: null,
                cliente: null,
                bucket_s3: null,
            },
            colorSistema: '#9508EB'
        }
    },
    computed:{
        idProyecto(){
            return this.$route.params.id_proyecto
        },
        proyecto(){
            return this.$route.params.data
        },
    },
    watch: {
        colorSistema(){
            let btn = document.getElementsByClassName('btn-preview');
            btn[0].style.backgroundColor = this.colorSistema;
        }
    },
    mounted(){
        if(!_.isEmpty(this.$route.params.data)){
            this.modoEditar();
        } else {
            this.limpiar();
        }
    },
    methods:{
        async modoEditar(){
            this.modoActualizar = true;
            this.model.nombre = this.proyecto.nombre;
            this.model.descripcion = this.proyecto.descripcion;
            this.model.tipo = this.proyecto.tipo;
            this.model.estado = this.proyecto.estado;
            this.model.base_datos = this.proyecto.base_datos;
            this.model.dominio = this.proyecto.dominio;
            if(this.proyecto.textos != null){
                this.model.empresa = this.proyecto.textos.empresa.texto;
                this.model.vendedor = this.proyecto.textos.vendedor.texto;
                this.model.cliente = this.proyecto.textos.cliente.texto;
                this.colorSistema = "#"+this.proyecto.textos.colores.texto;
            }
        },
        async postProyecto(){
            try {
                const valid = await this.$refs.validacion.validate();
                if(!valid)return;
                this.model.colorSistema = this.colorSistema.slice(1);
                this.model.bucket_s3 = this.model.base_datos;
                const {data} = await Service.postProyectoManager(this.model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$router.push({name:'manager.proyectos'});
            } catch(error){
                this.error_catch(error)
            }
        },
        async putProyecto(){
            try {
                const valid = await this.$refs.validacion.validate();
                if(!valid)return;
                this.model.colorSistema = this.colorSistema.slice(1);
                this.model.bucket_s3 = this.model.base_datos;
                const {data} = await Service.putProyectoManager(this.model, this.idProyecto);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$router.push({name:'manager.proyectos'});
            } catch(error){
                this.error_catch(error)
            }
        },
        async limpiar(){
            this.modoActualizar = false;
            this.model.nombre = null;
            this.model.descripcion = null;
            this.model.tipo = 1;
            this.model.estado = 1;
            this.model.base_datos= null;
            this.model.dominio = null;
            this.model.empresa = null;
            this.model.vendedor = null;
            this.model.cliente = null;
            this.model.bucket_s3 = null;
            this.colorSistema = '#9508EB';
            this.$refs.validacion.reset();
        },
    },
}
</script>
<style lang="scss" scoped>
.btn-back{
    width: 20px;
    height: 20px;
    color:#FFFFFF;
    border-radius: 20px;
    background-color: #000000;
}

.btn-preview{
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 193px;
    height: 36px;
    color: #FFFFFF;
    background-color: #9508EB;
    font-size: 18px;
}
</style>